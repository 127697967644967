@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body,
  #__next {
    height: 100%;
  }
  body,
  #__next {
    display: flex;
    flex-direction: column;
  }

  main {
    flex: 1 0 auto;
  }

  .site-footer {
    flex-shrink: 0;
  }

  #pera-wallet-connect-modal-wrapper,
  #pera-wallet-redirect-modal-wrapper,
  #pera-wallet-sign-txn-toast-wrapper,
  #defly-wallet-sign-txn-toast-wrapper {
    z-index: 99999;
  }
}
